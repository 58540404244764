import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const isBrowser = typeof window !== 'undefined';

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 100px 0 120px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 540px;
    margin-left: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
  margin-bottom: 20px;
`;

const QuoteBar = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand160};
`;

const QuoteInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
`;

const QuoteTitle = styled.div`
  margin-bottom: 20px;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.bodyTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.bodyTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Talent Funnel integration - Rotaready"
          description="Take your workforce management to the next level with a real-time integration between Rotaready and the Talent Funnel applicant tracking system."
          url="integrations/talent-funnel"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroTextWrapper>
                  <H1 uistyle="brand160" text="Talent Funnel and Rotaready" />
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">An intuitive integration designed to streamline your applicant tracking and employee scheduling.</H4>
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">With this integration employee records are created automatically within Rotaready when someone is marked as hired within Talent Funnel; saving you time and removing any duplication of work.</H4>
                </HeroTextWrapper>

                <CtaButtons>
                  <Button uistyle="primary" text="Learn more" size="lg" onClick={this.goToForm} />

                  <a href="https://www.talent-funnel.com" target="_blank" rel="noopener noreferrer">
                    <Button uistyle="primary" text="Visit Talent Funnel" ghost borderless size="lg" />
                  </a>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <GatsbyImage
                  image={data.heroImage.childImageSharp.gatsbyImageData}
                  alt="Screenshot of Talent Funnel ATS"
                  style={{ maxWidth: '700px' }}
                />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Create employee records automatically" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="The integration between Talent Funnel and Rotaready allows all employee data from Talent Funnel to be pulled directly into Rotaready; automatically creating new employee records. A new employee record will include everything you choose to capture about a new hire within Talent Funnel; from HMRC starter declaration, to their bank details." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Employee documents such as contracts and passports, can also be copied from Talent Funnel to Rotaready and are automatically saved against an employee's profile in the relevant document category." />
              </TextBlock>
              <TextBlock>
                <H3 uistyle="brand160" text="Already a Rotaready customer?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Setting up the integration is easy. Drop us a live chat or an email and our customer success team will guide you through it." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.bodyTopRef}>
              <TextBlock>
                <H3 uistyle="brand160" text="About Talent Funnel" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="With Talent Funnel's fully branded Careers Sites and responsive Applicant Tracking System, streamline your end-to-end hiring experience from attraction to onboarding." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Working with great brands like Dr. Martens, Compass Group, Franco Manca and more, Talent Funnel lets employers create beautiful Careers Sites that showcase their branding with no limits to create a unique candidate experience. It's all about your brand, not ours." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="We've created an ATS that is so intuitive and simple to use, it's a 'system that feels similar to use'. We let you hire your way, with no restrictions on your workflows and processes. Combined with automation, one-click Right-to-Work and referencing, powerful reporting and more, we save our customers time and increase their hiring ROI." />
              </TextBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Find out more about Talent Funnel"
                  render={props => <a {...props} href="https://www.talent-funnel.com" target="_blank" rel="noopener noreferrer" />}
                />
              </TextBlock>
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "integrations/talent-funnel/hero-desktop.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }
  }
`;
